<template>
  <b-card>
    <b-card-title>{{$t('negativeContent.title')}}
      <b-icon icon="question-circle" class="icon-tooltip" id="titleTooltip"></b-icon>
      <b-tooltip  target="titleTooltip" triggers="hover" variant="primary">
        {{$t(`negativeContent.tooltips.title`)}}
      </b-tooltip>
    </b-card-title>
    <b-row>
      <b-col cols="12" class="my-3 col-md-3">
        <div class="d-flex align-items-center flex-wrap">
          <div class="d-flex flex-wrap col-12">
            <div :class="getClassGeneral()"></div>
            <h4 class="ml-1">
              <strong>{{getTextGeneral()}}</strong>
            </h4>
          </div>
          <p class="text-muted mt-md-1">{{brand_safety.score ? brand_safety.score : 0}} {{$t('negativeContent.of')}} {{lengthItems}} {{$t('negativeContent.patterns')}}</p>
        </div>
      </b-col>

      <b-col cols="12" class="col-md-9">
        <b-row>
          <b-col cols="12">
            <h5>{{$t('negativeContent.verification')}}:</h5>
          </b-col>

          <b-col
            v-for="(item, index) in indicators"
            :key="index"
            cols="12"
            class="mt-1 col-md-6 col-lg-4"
          >
            <div class="d-flex centerContent">
              <div :class="getClassItem(item)"></div>
              <h4 class="ml-1 mb-0">
                <strong class="mr-1">{{$t(`negativeContent.${index}`)}}</strong>
                <b-icon font-scale="1" icon="question-circle" class="icon-tooltip" :id="index"></b-icon>
                <b-tooltip :target="index" triggers="hover" variant="primary">
                  {{$t(`negativeContent.tooltips.${index}`)}}
                </b-tooltip>
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BRow, BCol, BCard, BIcon, BTooltip, BCardTitle } from 'bootstrap-vue';
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BIcon,
    BTooltip,
    BCardTitle
  },
  props: {
    brand_safety: {
      Type: Object,
      required: true
    }
  },
  data() {
    return {
      default_data: {
        "sex": {
          "value": false
        },
        "crime": {
          "value": false
        },
        "toxic": {
          "value": false
        },
        "pranks": {
          "value": false
        },
        "alcohol": {
          "value": false
        },
        "politics": {
          "value": false
        },
        "religion": {
          "value": false
        },
        "offensive": {
          "value": false
        },
        "sentiment_negative": {
          "value": false
        }
      }
    }
  },
  created() {
  },
  computed: {
    lengthItems() {
      if (this.brand_safety && this.brand_safety.items) return Object.keys(this.brand_safety.items).length
      return 9
    },
    indicators() {
      if (this.brand_safety.items) return this.brand_safety.items
      return this.default_data
    }
  },
  methods: {
    getClassGeneral() {
      let clase = ''

      if (Object.keys(this.brand_safety).length === 0) clase = 'aprovado'
      else if (this.brand_safety.score === 0) clase = 'aprovado'
      else if (this.brand_safety.score >= 1 && this.brand_safety.score < 6) clase = 'promedio'
      else clase = 'peligro'

      return `circle-alcance ${clase}`
    },
    getClassItem(item) {
      let clase = ''

      if (item.value) clase = 'peligro'
      else clase = 'aprovado'

      return `circle-alcance ${clase}`
    },
    getTextGeneral() {
      let text = ''
      if (Object.keys(this.brand_safety).length === 0) text = this.$t('negativeContent.sure')
      else if (this.brand_safety.score === 0) text = this.$t('negativeContent.sure')
      else if (this.brand_safety.score >= 1 && this.brand_safety.score < 6) text = this.$t('negativeContent.neutral')
      else text = this.$t('negativeContent.danger')

      return text
    }
  }
}
</script>

<style scoped>
.h-100 {
  height: 100%;
}
</style>
